import React, { useState, useRef } from "react";
import { useMyContext } from "@/context/MyContext";
import styles from "./styles.module.scss";
import login_bg from "@/asset/login_bg.png";
import ha_logo from "@/asset/ha_logo.png";
   

import cn from "classnames";
import { getLogin } from "@/apiConfig/index";
import { useLoading } from "@/component/Loading";
import { message } from "antd";

const LogonPaage = () => {
  const { onLogon } = useMyContext();
  const [username, setUsername] = useState('');
  const passwordRef = useRef('');
  const onLoading = useLoading();

  const disabled = !(username && passwordRef.current);

  //账户登录
  const onLogonApp = async () => {
    if (disabled) {
      return;
    }
    onLoading(true);
    try {
      const result = await getLogin({
        username,
        password: passwordRef.current.value,
      });
      if (result.code === 200) {
        onLogon(result?.data, username);
      } else {
        message.error(
          "The username or password is incorrect, pls check with Administrator.",
          3
        );
      }
    } catch (e) {
      console.log("login fail:", e);
      message.error(
        "The username or password is incorrect, pls check with Administrator.",
        3
      );
    } finally {
      onLoading(false);
      passwordRef.current.value = '';
    }
  };

  // 处理用户名输入
  const handleUsernameInput = (e) => {
    setUsername(e.target.value.replace(/[\u4e00-\u9fa5\s]/gi, ""));
  };

  // 处理密码输入
  const handlePasswordInput = (e) => {
    passwordRef.current.value = e.target.value.replace(/[\u4e00-\u9fa5\s]/gi, "");
  }

  return (
    <div className={styles.root}>
      <div className="topBar">
        This platform is only for proof-of-concept trials, does not guarantee the retention of uploaded data, and should not be used for any sensitive or confidential information.
      </div>
      <div className="box">
        <div className="flex1">
          <img className="bgImg" src={login_bg} alt="background img" />
          <div className="login">
            <div className="loginHeader">
              <img src={ha_logo} className="logo" alt="logo" />
              <div className="logoTitle">Robotics Solution</div>
            </div>
            <div className="inputGroup">
              <div className="inputBox">
                <input
                  value={username}
                  onInput={handleUsernameInput}
                  className="input"
                />
              </div>
              {/* === Updated Start：Fix: Privacy Violation#(Line#83) - 20240924 - By PennYao === */}
              <div className="inputBox">
                <input
                  ref={passwordRef}
                  onInput={handlePasswordInput}
                  type="password"
                  className="input"
                />
              </div>
              {/* === Updated End：Fix: Privacy Violation#(Line#83) - 20240924 - By PennYao === */}
            </div>
            <div
              className={cn("login_btn", disabled && "disabled-btn")}
              onClick={onLogonApp}
            >
              Login
            </div>
            <div className="agreeBox"></div>
          </div>
        </div>
        <div className="flex2">
          <div className="banquan">
            Copyright © HOSPITAL AUTHORITY. All right reserved 2024.
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogonPaage;
